<template>
  <iframe :id="id" :src="url" frameborder="0" class="iframe-content" scrolling="auto" />
</template>
<script>
export default {
  name: 'IframeLayout',
  data() {
    return {
      url: '',
      id: ''
    }
  },
  mounted() {
    this.goUrl()
  },
  methods: {
    goUrl() {
      const url = this.$route.meta.url
      const id = this.$route.meta.routeId
      this.id = id
      if (url) {
        this.url = url
      }
    }
  }
}
</script>